import React, { useState, useEffect } from "react"
import "./index.scss"
import {
  getOrganizations,
  getOrganization,
  createOrganizations,
} from "../../services/organizations"
import { getUsers, getUser, createUsers,updateUsers } from "../../services/user"
import { getBranches } from "../../services/branch"
import auth from "../../services/auth"
import WebsiteLayout from "../../components/layout/inner-layout/inner-layout"
import ModalWindow from "../../components/common/modal-window/modal-window"
import Button from "../../components/common/button/button"
import AddCompanies from "../add-company"
import styles from "../manage-companies/index.scss"
import ConfirmNavLink from "../../components/common/confirm-nav-link/confirm-nav-link"
import { NotificationManager } from "react-notifications"
import { NotificationContainer } from "react-notifications"
import "react-notifications/lib/notifications.css"

const ManageCompanies = ({ setEditStateFn }) => {
  const [organizations, setOrganizations] = useState([])
  const [users, setUsers] = useState([])
  const [organization, setOrganization] = useState()
  const [modalOpen, setModalOpen] = useState(false)
  const [branches, setBranches] = useState([])
  let authObj = auth.getAuth()

  useEffect(() => {
    let authObj = auth.getAuth()
    console.log("authObj", authObj.user.branch.id)
    if (authObj.user !== undefined && authObj.user.role.type !== "branch_admin") {
      console.log("if")
      getOrganization(authObj.user.organization.id).then(organizations =>
        setOrganizations([organizations])
      )
    } else {
      console.log("else")
      getOrganizations().then(organizations => setOrganizations(organizations))
    }
  }, [])

  // const getBranchList = () => {
  //   getBranches().then(branches => {
  //     setBranches(branches)
  //   })
  // }

  // const onActiveHandler = (orgObj, type) => {
  //   console.log("orgjObj", orgObj)
  //   let obj = {
  //     blocked: true,
  //   }
  //   if (type === "active") {
  //     obj.blocked = true
  //   }
  //   getUsers().then(users => {
  //     console.log("users", users)
  //     const usr = users.filter(e => {
  //       if (e.organization && e.organization.id === orgObj._id) {
  //         e.blocked = true
  //         return e
  //       }
  //     })
  //     console.log(usr)
  //     setUsers(usr)
  //   })
  //   NotificationManager.success(
  //     "You have Deactivated all Users.",
  //     "Successful!",
  //     2000
  //   )
  // }

  // const onDeactivateHandle = (authObj, type) => {
  //   console.log("authObj", authObj)
  //   let obj = {
  //     blocked: true,
  //   }
  //   if (type === "active") {
  //     obj.blocked = true
  //   }
  //   getUsers().then(users => {
  //     console.log("User", users)
  //     const usr = users.filter(e => {
  //       if (e.branch && e.branch.id === authObj._id) {
  //         e.blocked = true
  //         return e
  //       }
  //     })
  //     setUsers(usr)
  //   })
  //   NotificationManager.success(
  //     "You have Deactivated all Users.",
  //     "Successful!",
  //     2000
  //   )
  // }

  const onClick = e => {
    setModalOpen(true)
  }

  const addCompany = values => {
    createOrganizations(values).then(organization => {
      setOrganizations([...organizations, organization])
      setModalOpen(false)
    })
  }

  const signUp = values => {
    createUsers(values).then(user => {
      setUsers([...users, user])
      setModalOpen(false)
    })
  }
  const getModalWindow = () => {
    if (modalOpen) {
      return (
        <ModalWindow title="Add Company">
          <AddCompanies addCompany={addCompany} />
        </ModalWindow>
      )
    } else {
      return ""
    }
  }

  return (
    <WebsiteLayout>
      <NotificationContainer />
      <div className="action">
        {authObj.user !== undefined && authObj.user.role.type === "branch_admin" &&
          authObj.user.branch.hasOrgs && (
            <div className="buttonWrap">
              {getModalWindow()}
              <Button
                id="add-company"
                type="button"
                label=" +   Add Company"
                btnStyle="primary"
                btnSize="large"
                onClick={onClick}
              />
            </div>
          )}
      </div>
      <br />
      <br />

      <table className="company-list">
        <thead>
          <tr>
            <th width="30%">Company</th>
            <th width="30%">Admins</th>
            <th width="30%">Action</th>
          </tr>
        </thead>

        <tbody>
          {authObj.user !== undefined && authObj.user.role.type === "branch_admin" && (
            <tr>
              <td>
                {authObj.user.role.type === "branch_admin" && (
                  <span> {authObj.user.branch.name}</span>
                )}
                {authObj.user.role.type === "org_manager" && (
                  <span> {authObj.user.organization.name}</span>
                )}
              </td>

              <td>{authObj.user.email}</td>
              <td>
                <div
                  className="buttonWrap"
                  style={{
                    display: "flex",
                  }}
                >
                  <ConfirmNavLink
                    to={`/edit-branch?user.id=${authObj.user.branch.id}`}
                  >
                    <Button
                      id="edit-company"
                      type="button"
                      btnStyle="primary"
                      label="Edit"
                      prependIcon="MdEdit"
                    />
                  </ConfirmNavLink>
                  {/* <span
                    style={{
                      marginLeft: "15px",
                    }}
                  >
                    <Button
                      id="deactivate-company"
                      type="submit"
                      label="Deactivate"
                      btnStyle="secondary"
                      value={!users.blocked ? "deactivated" : "active"}
                      onClick={e => onDeactivateHandle(authObj, e.target.value)}
                    />
                  </span> */}
                </div>
              </td>
            </tr>
          )}

          {authObj.user !== undefined && (authObj.user.role.type == "branch_admin" ||
            authObj.user.role.type === "org_manager") &&
            authObj.user.branch.hasOrgs &&
            organizations.map((org, index) => (
              <tr key={index}>
                <td>
                  <div> {org.name}</div>
                </td>
                <td>{org.admins ? org.admins : ""}</td>
                <td>
                  <div
                    className="buttonWrap"
                    style={{
                      display: "flex",
                    }}
                  >
                    <ConfirmNavLink to={`/edit-organization?oid=${org.id}`}>
                      <Button
                        id="edit-company"
                        type="button"
                        btnStyle="primary"
                        label="Edit"
                        prependIcon="MdEdit"
                      />
                    </ConfirmNavLink>
                    {/* <span
                      style={{
                        marginLeft: "15px",
                      }}
                    >
                      <Button
                        id="deactivate-company"
                        type="submit"
                        label="Deactivate"
                        btnStyle="secondary"
                        value={!users.blocked ? "deactivated" : "active"}
                        onClick={e => onActiveHandler(org, e.target.value)}
                      />
                    </span> */}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </WebsiteLayout>
  )
}

export default ManageCompanies
