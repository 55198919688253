import axios from "axios"
import util from "./util"

export const getBranches = async => {
  return new Promise(function(resolve, reject) {
    axios.get("/branches").then(
      response => {
        console.log("branches queried successfully", response)
        // resolve(util.fixUrls(response.data))
        resolve(response.data)
      },
      error => {
        console.log("getBranches ERROR: branches not found", error)
        reject(error)
      }
    )
  })
}
export const getBranch = async id => {
  return new Promise(function(resolve, reject) {
    axios.get("/branches/" + id).then(
      response => {
        console.log("branches queried successfully", response)
        // resolve(util.fixUrls(response.data))
        resolve(response.data)
      },
      error => {
        console.log("getBranches ERROR:branches not found", error)
        reject(error)
      }
    )
  })
}

export const createBranches = async branches => {
  return new Promise(function(resolve, reject) {
    axios.post("/branches", branches).then(
      response => {
        console.log("Branch created successfully", response)

        var updatedBranches = response.data
        updatedBranches.canEdit = true
        updatedBranches.canDelete = true

        resolve(util.fixUrls(updatedBranches))
      },
      error => {
        console.log("createBranches error:", error)
        reject(error)
      }
    )
  })
}
export const updateBranches = async (id, branches) => {
  return new Promise(function(resolve, reject) {
    axios.put("/branches/" + id, branches).then(
      response => {
        console.log("Branches " + id + " updated successfully", response)
        // response.data is the updated story object
        resolve(util.fixUrls(response.data))
      },
      error => {
        console.log("updateBranches error:", error)
        reject(error)
      }
    )
  })
}


export default {
  getBranches,
  getBranch,
  updateBranches,
  createBranches,
}
